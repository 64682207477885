<template>
  <div>
    <div class="title">
      <span>AIshipgo x {{ ruleForm.plat }}账号关联</span>
      <p>
        关联后，您可以直接将AIshipgo处理的数据同步到您的{{
          ruleForm.plat
        }}店铺中； 同时，也可以在AIshipgo中读取您店铺商品数据。
      </p>
    </div>
    <div class="AIshipgoID">
      <span>当前{{ ruleForm.plat }}账号</span>
      <div>{{ ruleForm.platUsername }}</div>
    </div>

    <el-form
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      hide-required-asterisk
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <!-- <el-form-item label="关联Dhgate账号" prop="DhgateName">
        <el-input
          placeholder="请输入Dhgate账号名"
          v-model="ruleForm.platUsername"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="AIshipgo绑定手机号" prop="phone">
        <el-input
          placeholder="请输入Dhgate绑定手机号"
          v-model.trim="ruleForm.phone"
        ></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="smsCode">
        <div class="form-div">
          <el-input
            placeholder="请输入验证码"
            v-model.trim="ruleForm.smsCode"
          ></el-input>
          <el-button
            @click="() => getVerification()"
            plain
            type="primary"
            :disabled="isCell_phone || isBut"
            >{{ butName }}</el-button
          >
        </div>
      </el-form-item>

      <el-form-item>
        <div class="sub">
          <el-button type="primary" @click="submitForm()" :disabled="isRegister"
            >确认关联</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getRelevance,
  PostBindAishipgoAccount,
  postverificationCode,
} from '../../api/login';

import '../../assets/css/customElement.less';
export default {
  name: '',
  data() {
    return {
      isBut: false,
      butName: '获取验证码',
      butNum: 60,
      isCell_phone: true,
      isRegister: true,
      ruleForm: {
        plat: '--',
        platUserId: '--',
        platUsername: '--',
        phone: '',
        smsCode: '',
      },
      rules: {
        DhgateName: [
          {
            required: true,
            message: '请输入DhgateName账号名称',
            trigger: 'blur',
          },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: this.isValidPhoneNumber,
            message: '请输入有效的手机号',
            trigger: 'change',
          },
        ],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
    };
  },
  mounted() {},
  beforeMount() {
    this.getItemRelevance();
  },
  methods: {
    // 提交
    async submitForm() {
      const res = await PostBindAishipgoAccount(this.ruleForm);
      if (res.data.state === '0x0000' && !!res.data.data) {
        localStorage.setItem('tokenKey', res.data.data.token);
        localStorage.setItem('isAdministrator', res.data.data.isAdministrator);
        const redirect = this.$route.query.redirect;
        if (redirect) {
          window.location.href = redirect;
        } else {
          this.$router.push('/');
        }
      } else {
        this.$message.error(res.data.message || '绑定失败');
      }
    },
    isValidPhoneNumber(rule, value, callback) {
      const pattern =
        /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!pattern.test(value)) {
        this.isCell_phone = true;
        callback(true);
      } else {
        this.isCell_phone = false;
      }
    },
    // 倒计时
    countDown() {
      this.butName = '重新获取' + this.butNum + 's';
      this.butNum--;
      let trueval = setInterval(() => {
        this.butName = '重新获取' + this.butNum + 's';
        this.butNum--;
        if (this.butNum === 0) {
          this.isBut = false;
          this.butName = '获取验证码';
          this.butNum = 60;
          clearInterval(trueval);
        }
      }, 1000);
    },
    // 获取关联信息
    async getItemRelevance() {
      const token = localStorage.getItem('tokenKey');
      const res = await getRelevance({ token });
      console.log(res);
      if (res.data.state && !!res.data.data) {
        this.ruleForm.plat = res.data.data.plat;
        this.ruleForm.platUserId = res.data.data.platUserId;
        this.ruleForm.platUsername = res.data.data.platUsername;
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning',
        });
      }
    },
    // 获取验证码
    async getVerification() {
      const data = {
        phone: this.ruleForm.phone,
        smsType: 1,
      };
      const res = await postverificationCode(data);
      if (res.data.state === '0x0000') {
        this.isBut = true;
        this.countDown();
        this.$message({
          message: '验证码已发送',
          type: 'success',
        });
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning',
        });
      }
    },
  },
  watch: {
    ruleForm: {
      handler(newV) {
        const _this = this;
        if (Object.values(newV).every((val) => val !== '')) {
          if (!_this.isCell_phone) _this.isRegister = false;
        } else {
          _this.isRegister = true;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  width: 460px;

  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0px;
  color: #6c38e0;
  p {
    font-size: 14px;
    color: #4e5969;
  }
}
.AIshipgoID {
  font-family: PingFang SC;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  margin-bottom: 32px;
  margin-top: 32px;
  span {
    font-size: 14px;
    color: #4e5969;
  }
  div {
    font-size: 24px;
    color: #1d2129;
  }
}
.el-form {
  width: 468px;
  .el-form-item {
    .form-div {
      display: flex;
      .el-button {
        margin-left: 10px;
      }
    }
    .sub {
      width: 100%;
      .el-button {
        width: 100%;
      }
    }
  }
}
</style>
